export function DescriptionQuestion({
  text,
  response,
}: {
  text: string;
  response: string | null;
}) {
  if (response === null || response === '') {
    return null;
  }

  return (
    <div>{text}<br /><span className="font-bold ml-2">{response}</span></div>
  );
}
