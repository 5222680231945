import { RadioGroup } from '@headlessui/react';
import { ReactNode } from 'react';

type SelectOption<T> = {
  label: ReactNode;
  value: T;
};

export function StackedRadioField<T extends string>({
  error,
  label,
  onChange,
  options,
  value,
}: {
  error?: string;
  label?: string;
  onChange?: (value: T) => void;
  options: SelectOption<T>[];
  value: T | null;
}) {
  const selectedOption = options.find((option) => option.value === value) ?? null;

  return (
    <div>
      <RadioGroup onChange={(option) => {
        if (onChange !== undefined && option !== null) {
          onChange(option.value);
        }
      }} value={selectedOption}>
        <>
          {label && (
            <RadioGroup.Label className={`
              ${error ? 'text-red-600 has-error' : 'text-gray-900'}
              block mb-2 font-medium leading-6
            `}>
              {label}
            </RadioGroup.Label>
          )}
          <div className="space-y-4">
            {options.map((option) => (
              <RadioGroup.Option
                key={option.value}
                value={option}
                className={({ checked }) => `
                  ${checked ? 'border-indigo-600 ring-2 ring-indigo-600' : 'border-gray-300'}
                  relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm
                  focus:outline-none sm:flex sm:justify-between
                `}
              >
                <RadioGroup.Label as="span">{option.label}</RadioGroup.Label>
              </RadioGroup.Option>
            ))}
          </div>
        </>
      </RadioGroup>
      {error && (
        <p className="mt-1 text-sm text-red-600">{error}</p>
      )}
    </div>
  );
}
